import React from 'react';

import { SiteLayout } from '@layouts';

import { ErrorBlock404 } from '../../../components/global-error-block/blocks';

const ErrorPage404 = () => <ErrorBlock404 />;

ErrorPage404.layout = {
    component: SiteLayout,
};

export default ErrorPage404;
